<template>
  <Section class="post-preview">
    <h2 v-html="title"></h2>

    <div>Posted on: {{ formattedDate }}</div>

    <div v-html="excerpt"></div>

    <router-link
      :to="{
        name: 'article',
        params: {
          year: year,
          month: month,
          day: day,
          slug: slug
        }
      }"
    >
      Read more...
    </router-link>

    <template v-slot:aside v-if="featuredImageUrl">
      <LazyImg :url="featuredImageUrl" :description="title" />
    </template>
  </Section>
</template>

<script>
import Section from '@/components/Section'
import LazyImg from '@/components/LazyImg'

import PostMixin from '@/mixins/wp_post'

function pad(n, width, z) {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

function formatDate(date) {
  if (!date) {
    return ''
  }

  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${day}/${month}/${year}`
}

export default {
  name: 'PostPreview',

  mixins: [PostMixin],

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  components: {
    LazyImg,
    Section
  },

  computed: {
    title() {
      return this.post.title.rendered
    },

    date() {
      return new Date(this.post.date)
    },

    formattedDate() {
      return formatDate(this.date)
    },

    day() {
      return this.date.getDate()
    },

    month() {
      return pad(this.date.getMonth() + 1, 2)
    },

    year() {
      return this.date.getFullYear()
    },

    excerpt() {
      return this.post.excerpt.rendered
    },

    slug() {
      return this.post.slug
    }
  }
}
</script>

<style lang="sass" scoped>
.post-preview
</style>
