<template lang="html">
  <div class="lazy-image" ref="element" :class="{'is-loading': !active}">
    <Transition name="fade">
      <img
        v-if="active"
        :class="orientationClass"
        :src="url"
        :alt="description"
        draggable="false"
      />
    </Transition>
  </div>
</template>

<script>
const ORIENTATION = {
  PORTRAIT: 'PORTRAIT',
  LANDSCAPE: 'LANDSCAPE',
}

import sizable from '@/mixins/sizable'

export default {
  name: 'LazyImage',

  mixins: [sizable],

  props: {
    egar: {
      type: Boolean,
      required: false,
      default: false,
    },

    url: {
      type: String,
      default: '',
      note: 'The source of the image to be loaded in',
    },

    description: {
      type: String,
      default: '',
      note: 'Used in image alt tag',
    },
  },

  data() {
    return {
      loading: false,
      observer: null,
      intersected: false,
    }
  },

  computed: {
    active() {
      if (this.egar) {
        return !!this.url
      } else {
        return this.url && !this.loading && this.intersected
      }
    },

    element() {
      return this.$refs.element
    },

    orientation() {
      return this.height > this.width
        ? ORIENTATION.PORTRAIT
        : ORIENTATION.LANDSCAPE
    },

    orientationClass() {
      return `is-${this.orientation.toLowerCase()}`
    },

    shouldGetData() {
      if (this.egar) {
        return !!this.url
      } else {
        return this.intersected && this.url
      }
    },
  },

  methods: {
    getData() {
      if (this.shouldGetData) {
        this.loading = true

        let img = new Image()

        img.addEventListener('load', () => {
          this.loading = false
        })

        img.src = this.url
      }
    },

    setObserver() {
      this.observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.intersected = true
            this.getData()
            observer.unobserve(this.element)
          }
        })
      })

      this.observer.observe(this.element)
    },
  },

  mounted() {
    this.setObserver()
    this.getData()
  },

  unmounted() {
    this.observer.observe(this.element)
  },

  watch: {
    imagePath() {
      this.getData()
    },
  },
}
</script>

<style lang="sass" scoped>
.fade-enter-active, .fade-leave-active
  transition: opacity .3s

.fade-enter, .fade-leave-to
  opacity: 0


.lazy-image

  > img
    width: 100%
    height: 100%
    object-fit: cover
    user-select: none
    user-drag: none
    -webkit-touch-callout: none
</style>
