<template>
  <header class="header">
    <Loader :loading="loading" />

    <Container>
      <div class="header-inner">
        <RouterLink class="logo-link" :to="{name: 'home'}" title="Dan BJJ">
          <div class="logo-wrapper" :ratio="[1, 1]">
            <LazyImg class="logo" :url="require('../assets/img/logo.png')" />
          </div>
        </RouterLink>

        <button class="nav-button" aria-label="Navigation" @click="$emit('toggleNav')">
          <Sprite :name="menuIcon" />
        </button>

        <Phone />

        <Navigation @close="$emit('toggleNav')" :active="navigating" />
      </div>
    </Container>
  </header>
</template>

<script>
import Container from '@/components/Container'
import Loader from '@/components/Loader'
import Navigation from '@/components/Navigation'
import Phone from '@/components/Phone'
import Sprite from '@/components/Sprite'
import LazyImg from '@/components/LazyImg'

export default {
  name: 'Header',

  props: {
    navigating: {
      type: Boolean,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    Container,
    Loader,
    Navigation,
    Phone,
    Sprite,
    LazyImg,
  },

  computed: {
    menuIcon() {
      return this.navigating ? 'close' : 'menu'
    },
  },
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.header
  background: $color-dark
  transition: padding $transition-duration $transition-easing
  width: 100%
  z-index: 11

  @include media($media-gt-big)
    position: fixed

  .is-scrolling &
    transition-delay: $transition-delay

  @include media($media-gt-big)
    .is-scrolling &
      padding: 0px

  .nav-button
    position: absolute
    top: $pad / 2
    right: $pad / 2
    z-index: 1000
    border: 0
    background-size: cover
    width: 35px
    height: 35px
    padding: 0
    background: none

    .sprite
      width: 100%
      height: 100%
      fill: $color-light

    @include media($media-gt-big)
      display: none

  .header-inner
    width: 100%
    position: relative

    @include media($media-gt-big)
      display: flex
      flex-direction: columns
      justify-content: space-between
      position: sticky
      top: 0
      z-index: 900

  .logo-link
    @include media($media-gt-big)
      margin-bottom: -60px
      transition: width $transition-duration, margin-bottom $transition-duration, height $transition-duration
      transition-easing: $transition-easing
      z-index: 10
      width: 150px
      height: 150px

      .is-scrolling &
        width: 110px
        height: 110px
        transition-delay: $transition-delay
        margin-bottom: -45px

    .logo-wrapper
      margin: auto
      width: 150px
      height: 150px

    .logo
      width: 150px
      height: 150px
      display: block


  ::v-deep .phone
    position: absolute
    top: $pad / 2
    left: $pad / 2
    z-index: 999

    .phone-icon
      width: 30px
      height: 30px

      @include media($media-gt-big)
        width: 22px
        height: 22px


    font-weight: bold

    .number
      display: none

    @include media($media-gt-big)
      left: auto
      right: 0
      top: 0

      .number
        display: block
</style>
