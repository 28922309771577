import { render, staticRenderFns } from "./QuoteIcon.vue?vue&type=template&id=378b16ec&"
import script from "./QuoteIcon.vue?vue&type=script&lang=js&"
export * from "./QuoteIcon.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./QuoteIcon.vue?vue&type=style&index=0&id=378b16ec&prod&lang=sass&scpoed=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "63830236"
  
)

export default component.exports