import {decodeHtml} from '@/filters'

const LOADING_TEXT = 'Loading...'

function pad(n, width, z) {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export default {
  computed: {
    embedded() {
      return this.post._embedded || {}
    },

    featuredMedia() {
      return this.embedded['wp:featuredmedia'] || {}
    },

    featuredImageMediaDetails() {
      return this.featuredImage.media_details || {}
    },

    featuredImageSizes() {
      return this.featuredImageMediaDetails.sizes || {}
    },

    featuredImageVersions() {
      return Object.keys(this.featuredImageSizes).map((key) => {
        const size = this.featuredImageSizes[key]

        return {
          width: size.width,
          url: size.source_url
        }
      })
    },

    featuredImage() {
      return this.featuredMedia['0'] || {}
    },

    featuredImageUrl() {
      return this.featuredImage.source_url || null
    },

    hasFeaturedImage() {
      return !!this.featuredImageUrl
    },

    title() {
      return this.post.title ? this.post.title.rendered : LOADING_TEXT
    },

    excerpt() {
      return this.post.excerpt ? this.post.excerpt.rendered : LOADING_TEXT
    },

    slug() {
      return this.post.slug
    },

    date() {
      return new Date(this.post.date)
    },

    day() {
      return this.date.getDate()
    },

    month() {
      return pad(this.date.getMonth() + 1, 2)
    },

    year() {
      return this.date.getFullYear()
    },

    content() {
      return this.post.content.rendered || LOADING_TEXT
    }
  }
}
