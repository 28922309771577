import {request} from '@/lib/request'

function classDaysPath() {
  return `/class_day`
}

export function readClassDays() {
  return request('get', classDaysPath()).then(({data}) => {
    return data
  })
}
