<template>
  <div class="email">
    <a :href="`mailto:${address}`" class="contact-link u-email">
      <Sprite name="email" class="email-icon" />
      <div class="address value">
        {{ address }}
      </div>
    </a>
  </div>
</template>

<script>
import Sprite from '@/components/Sprite'

const email = process.env.VUE_APP_CONTACT_EMAIL

export default {
  name: 'Email',

  data() {
    return {
      address: email
    }
  },

  components: {
    Sprite
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.email
  .contact-link
    display: flex
    align-items: center
    justify-content: center

  .email-icon
    margin-right: 10px
    width: 22px
    height: 22px
    fill: $color-light

  a
    text-decoration: none
    color: $color-light
</style>
