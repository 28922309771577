<template>
  <transition name="fade">
    <div v-if="active" class="overlay" @click.self="close">
      <div class="overlay-inner">
        <button @click="close" class="closer"><Sprite name="close" /></button>

        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import Sprite from '@/components/Sprite'

const ACTIVE_BODY_CLASS = 'with-overlay'

export default {
  name: 'Overlay',

  props: {
    active: {
      type: Boolean,
      required: true
    }
  },

  components: {
    Sprite
  },

  methods: {
    close() {
      this.$emit('close')
    }
  },

  watch: {
    active(value) {
      if (value) {
        document.body.classList.add(ACTIVE_BODY_CLASS)
      } else {
        document.body.classList.remove(ACTIVE_BODY_CLASS)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.overlay
  position: fixed
  z-index: 999999
  top: 0
  left: 0
  bottom: 0
  right: 0
  background: rgba(0,0,0,0.3)
  display: flex
  align-items: center
  justify-content: center

  .overlay-inner
    width: 100%
    max-width: 800px
    position: relative
    padding: $pad / 2
    background: $color-light

    @include media($media-gt-big)
      width: 90%
      height: 100%
      max-height: 800px
      padding: $pad

  .closer
    width: 24px
    height: 24px
    padding: 0
    position: absolute
    right: 2px
    top: 4px
    background: none
    border: none

    ::v-deep .sprite
      width: 24px
      height: 24px
      fill: $color-dark
</style>
