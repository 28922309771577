<template>
  <div class="page-fundamentals-classes">
    <Banner :image-versions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first fundamentals BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <WrappedSection slug="fundamentals-intro" className="is-odd" />
    <WrappedSection slug="fundamentals-escapes" className="is-even" />
    <WrappedSection slug="fundamentals-sweeps" className="is-odd" />
    <WrappedSection slug="fundamentals-submissions" className="is-even" />
    <WrappedSection slug="fundamentals-passing" className="is-odd" />
    <WrappedSection slug="fundamentals-takedowns" className="is-even" />
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import WrappedSection from '@/components/WrappedSection'

export default {
  name: 'FundamentalsClasses',

  mixins: [page],

  data() {
    return {
      pageSlug: 'fundamentals-classes',
    }
  },

  components: {
    Banner,
    WrappedSection,
  },
}
</script>

<style lang="sass" scoped>
.page-fundamentals-classes
</style>
