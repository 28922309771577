<template>
  <div class="banner" :class="{'has-image': !!imageVersions.length}">
    <ResponsiveImage
      class="background"
      :image-versions="imageVersions"
      :width="width"
      :egar="true"
      v-if="!!imageVersions.length"
    />

    <div class="words">
      <slot />
    </div>
  </div>
</template>

<script>
import ResponsiveImage from '@/components/ResponsiveImage'
import sizable from '@/mixins/sizable'

export default {
  name: 'Banner',

  mixins: [sizable],

  props: {
    imageVersions: {
      required: false,
      type: Array,
      default() {
        return []
      }
    },
  },

  components: {
    ResponsiveImage,
  },
}
</script>

<style lang="sass">
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.banner
  width: 100%
  position: relative
  display: flex
  justify-content: center
  align-items: center
  padding: 0
  overflow: hidden
  background: rgba($color-dark, 0.50)

  @include media($media-gt-big)
    padding: 262px $pad $pad $pad

  &.has-image
    padding-top: 0
    height: 350px

    @include media($media-gt-big)
      height: 700px

    @include media($media-gt-very-big)
      height: 800px

    .background
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      pointer-events: none
      background: $color-dark-section-background
      display: flex
      align-items: center
      justify-content: center
      color: #ffffff

      > img
        width: 100%
        height: 100%
        object-fit: cover
        max-width: 1600px

  .words
    z-index: 100
    font-size: 22px
    color: $color-light
    padding: $pad
    font-weight: bold
    text-align: center
    z-index: 1

    @include media($media-gt-big)
      position: absolute
      bottom: 0
      width: 100%

  &.has-image
    .words
      background: rgba($color-dark, 0.50)
</style>
