<template>
  <li class="timetable-session">
    <span v-if="showAge" class="age">{{ age }}</span>
    <span class="type">{{ type }}</span>
    <span class="level">{{ level }}</span>
    <span class="time">{{ start }} - {{ end }}</span>
  </li>
</template>

<script>
export default {
  name: 'TimetableSession',

  props: {
    session: {
      type: Object,
      required: true
    },

    types: {
      type: Object,
      required: true
    },

    levels: {
      type: Object,
      required: true
    },

    ages: {
      type: Object,
      required: true
    },

    filter: {
      type: Array,
      required: false
    }
  },

  computed: {
    showAge() {
      return !this.filter || this.filter.length > 1
    },

    type() {
      return this.types[this.session.acf.type].name.toLowerCase()
    },

    level() {
      return this.levels[this.session.acf.level].name.toLowerCase()
    },

    age() {
      return this.ages[this.session.acf.age].name
    },

    start() {
      return this.session.acf.start_time.slice(0, 5)
    },

    end() {
      return this.session.acf.end_time.slice(0, 5)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.timetable-session
  text-align: left
  border-bottom: 1px solid rgba($color-dark, 0.3)
  width: 100%
  text-align: right

  &:last-of-type
    border: none

  &>span
    padding-right: 4px

    &:last-of-type
      padding: 0

  @include media($media-gt-small)
    padding: $pad/2 0

  @include media($media-gt-medium)
    text-align: auto

  @include media($media-gt-big)

    .age
      display: inline-block
      min-width: 120px

    .type
      display: inline-block
      min-width: 100px

    .level
      display: inline-block
      min-width: 130px
</style>
