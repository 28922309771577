<template>
  <div class="container">
    <div class="contained">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Container',
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"

.container
  width: 100%

  .contained
    width: 100%
    max-width: 1200px
    margin: auto
    padding: 20px

    ::v-deep h1
      background: none
      color: $color-light-section-text
      padding: 0
      padding-top: $pad
      font-size: 36px
</style>
