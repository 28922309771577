import types from './mutations/types'

import * as classDays from '@/lib/class_days'
import * as classTypes from '@/lib/class_types'
import * as classLevels from '@/lib/class_levels'
import * as classes from '@/lib/classes'
import * as gallery from '@/lib/gallery'
import * as pages from '@/lib/pages'
import * as posts from '@/lib/posts'
import * as sections from '@/lib/sections'
import * as classAges from '@/lib/class_ages'
import * as instructors from '@/lib/instructors'

export default {
  readPage({commit}, {slug}) {
    commit(types.REQUEST_PAGE)

    return pages
      .readPage(slug)
      .then((page) => {
        const readAt = Date.now()
        commit(types.RECEIVE_PAGE, {slug, page, readAt})
      })
      .catch(() => {
        console.error(`could not get page with slug: ${slug}`)
      })
      .finally(() => {
        commit(types.REQUESTED_PAGE)
      })
  },

  readSection({commit}, {slug}) {
    commit(types.REQUEST_SECTION)

    return sections
      .readSection(slug)
      .then((section) => {
        const readAt = Date.now()
        commit(types.RECEIVE_SECTION, {slug, section, readAt})
      })
      .catch(() => {
        console.error(`could not get section with slug: ${slug}`)
      })
      .finally(() => {
        commit(types.REQUESTED_SECTION)
      })
  },

  readClasses({commit}) {
    commit(types.REQUEST_CLASSES)

    return classes
      .readClasses()
      .then((classes) => {
        const readAt = Date.now()
        commit(types.RECEIVE_CLASSES, {classes, readAt})
      })
      .finally(() => {
        commit(types.REQUESTED_CLASSES)
      })
  },

  readClassLevels({commit}) {
    commit(types.REQUEST_CLASS_LEVELS)

    return classLevels
      .readClassLevels()
      .then((classLevels) => {
        const readAt = Date.now()
        commit(types.RECEIVE_CLASS_LEVELS, {classLevels, readAt})
      })
      .finally(() => {
        commit(types.REQUESTED_CLASS_LEVELS)
      })
  },

  readClassTypes({commit}) {
    commit(types.REQUEST_CLASS_TYPES)

    return classTypes
      .readClassTypes()
      .then((classTypes) => {
        const readAt = Date.now()
        commit(types.RECEIVE_CLASS_TYPES, {classTypes, readAt})
      })
      .finally(() => {
        commit(types.REQUESTED_CLASS_TYPES)
      })
  },

  readClassAges({commit}) {
    commit(types.REQUEST_CLASS_AGES)

    return classAges
      .readClassAges()
      .then((classAges) => {
        const readAt = Date.now()
        commit(types.RECEIVE_CLASS_AGES, {classAges, readAt})
      })
      .finally(() => {
        commit(types.REQUESTED_CLASS_AGES)
      })
  },

  readClassDays({commit}) {
    commit(types.REQUEST_CLASS_DAYS)

    return classDays
      .readClassDays()
      .then((classDays) => {
        const readAt = Date.now()
        commit(types.RECEIVE_CLASS_DAYS, {classDays, readAt})
      })
      .finally(() => {
        commit(types.REQUESTED_CLASS_DAYS)
      })
  },

  readPosts({commit}) {
    commit(types.REQUEST_POSTS)

    return posts
      .readPosts()
      .then((posts) => {
        const readAt = Date.now()
        commit(types.RECEIVE_POSTS, {posts, readAt})
      })
      .finally(() => {
        commit(types.REQUESTED_POSTS)
      })
  },

  readPost({commit}, {slug}) {
    commit(types.REQUEST_POST)

    return posts
      .readPost(slug)
      .then((post) => {
        const readAt = Date.now()
        commit(types.RECEIVE_POST, {slug, post, readAt})
      })
      .finally(() => {
        commit(types.REQUESTED_POST)
      })
  },

  readGalleryItems({commit}) {
    commit(types.REQUEST_GALLERY_ITEMS)

    return gallery
      .getGallery()
      .then((items) => {
        const readAt = Date.now()
        commit(types.RECEIVE_GALLERY_ITEMS, {items, readAt})
      })
      .finally(() => {
        commit(types.REQUESTED_GALLERY_ITEMS)
      })
  },

  readInstructors({commit}) {
    commit(types.REQUEST_INSTRUCTORS)

    return instructors
      .readInstructors()
      .then((instructors) => {
        const readAt = Date.now()
        commit(types.RECEIVE_INSTRUCTORS, {instructors, readAt})
      })
      .finally(() => {
        commit(types.REQUESTED_INSTRUCTORS)
      })
  }
}
