<template>
  <div class="page-adults-classes">
    <Banner :image-versions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first adults BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <WrappedSection slug="adult-classes" className="is-odd" />

    <Section class="is-even">
      <h2>Adult BJJ classes timetable</h2>

      <ClassDataWrapper :filter="['Adults']" />
    </Section>

    <WrappedSection slug="bjj-fundamentals" className="is-odd">
      <RouterLink :to="{name: 'classes.fundamentals'}">
        Find out more
      </RouterLink>
    </WrappedSection>

    <WrappedSection slug="gi-classes" className="is-even" />

    <WrappedSection slug="no-gi-classes" className="is-odd" />

    <WrappedSection slug="open-mat-classes" className="is-even" />

    <LocationSection className="is-odd" />
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import ClassDataWrapper from '@/components/ClassDataWrapper'
import Section from '@/components/Section'
import WrappedSection from '@/components/WrappedSection'
import LocationSection from '@/components/LocationSection'

export default {
  name: 'AdultClasses',

  mixins: [page],

  data() {
    return {
      pageSlug: 'adult-classes',
    }
  },

  components: {
    Banner,
    ClassDataWrapper,
    Section,
    WrappedSection,
    LocationSection,
  },
}
</script>

<style lang="sass" scoped>
.page-adults-classes
</style>
