<template>
  <div class="gallery">
    <Carousel
      :navigationEnabled="true"
      :perPage="1"
      :perPageCustom="[
        [600, 2],
        [800, 3],
        [1000, 4]
      ]"
      :paginationEnabled="false"
      class="carousel"
    >
      <Slide class="slide" v-for="item in items" :key="item.id">
        <Item @selected="itemSelected" :item="item" />
      </Slide>
    </Carousel>

    <Overlay :active="!!selectedItem.url" @close="onOverlayClose">
      <LazyImg
        class="image"
        :url="selectedItem.url"
        description="selectedItem.description"
      />
    </Overlay>
  </div>
</template>

<script>
import {Carousel, Slide} from '@/lib/vue-carousel'
import Overlay from '@/components/Overlay'
import LazyImg from '@/components/LazyImg'

import Item from './Item'

export default {
  name: 'Gallery',

  props: {
    items: {
      required: true,
      type: Array
    }
  },

  components: {
    Carousel,
    Overlay,
    Item,
    LazyImg,
    Slide
  },

  data() {
    return {
      selectedItem: {}
    }
  },

  methods: {
    itemSelected(item) {
      this.selectedItem = item
    },

    onOverlayClose() {
      this.selectedItem = {}
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.gallery
  .carousel

  .slide
    display: flex
    justify-content: center
    align-items: center

  .image
    width: 100%
    height: 100%
</style>
