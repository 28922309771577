<template>
  <div class="page-home">
    <Banner :image-versions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <WrappedSection slug="home" className="is-odd" />

    <SectionDataWrapper sectionSlug="classes-preview">
      <template v-slot:default="slotProps">
        <Section class="is-even">
          <h2 v-html="slotProps.title"></h2>

          <div v-html="slotProps.content"></div>
          <p>
            See our
            <RouterLink :to="{name: 'classes'}">classes</RouterLink> information
            for more details.
          </p>

          <ClassDataWrapper />
        </Section>
      </template>
    </SectionDataWrapper>

    <Section class="is-odd">
      <Carousel
        :navigationEnabled="true"
        :perPage="1"
        :paginationEnabled="true"
        :autoplay="true"
        :autoplay-timeout="8000"
        :autoplay-hover-pause="true"
        :loop="true"
        class="carousel"
      >
        <Slide
          class="slide"
          v-for="testimonial in testimonials"
          :key="testimonial.id"
        >
          <Testimonial :testimonial="testimonial"></Testimonial>
        </Slide>
      </Carousel>
    </Section>

    <LatestPosts className="is-even" />

    <LocationSection contactLink className="is-odd" />

    <GallerySection className="is-even" />
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import ClassDataWrapper from '@/components/ClassDataWrapper'
import GallerySection from '@/components/GallerySection'
import Section from '@/components/Section'
import SectionDataWrapper from '@/components/SectionDataWrapper'
import Testimonial from '@/components/Testimonial'

import WrappedSection from '@/components/WrappedSection'
import LocationSection from '@/components/LocationSection'

import {Carousel, Slide} from '@/lib/vue-carousel'

import LatestPosts from './LatestPosts'

const testimonials = [
  {
    id: 0,
    content:
      "I've been training with Dan at Koba Bjj for nearly nine years, I feel very lucky I have been apart of this family from day one. Dan brings bjj training to a whole new level, he is a great teacher and has a vast knowledge and skillset to implement his way of teaching, he isn't just a coach but also a very close friend. He has established a club where like minded people come together to train and make friends, keep fit, have fun and learn the art of Brazilian jiujitsu.",
    author: 'James',
  },
  {
    id: 1,
    content:
      "I'm so grateful to have started my jiu jitsu journey at the Koba women's class. Starting at the women's class made the introduction to jiu jitsu a whole lot less intimidating, and a whole lot of fun!  I went there to learn bjj not realising how many other benefits would come along side that. I've never been in a gym environment that feels as welcoming as Koba does. You know that everyone in there is rooting for you and you are there to all learn together.  You won't ever regret showing up to a session that's for sure.",
    author: 'Evie',
  },
  {
    id: 2,
    content: `I first started Brazilian jiu-jitsu because I was looking for a decent form of self-defence and BJJ was recommended to me. I was amazed that there was a local club so I signed up and started training straight away.
<br><br>
I really think that training and sparring in jiu-jitsu has made me find out about myself but also about learning something completely new in an intense but cooperative way.
At the age of 57 I never thought that I'd train and learn a martial art like jiu-jitsu and I am also surprised how much I love to train. It is such a great feeling after sparring sessions!
<br><br>
Koba is genuinely a very friendly and welcoming club. Dan and the other instructors are certainly very patient and they all have a great sense of humour which really helps with learning and training. BJJ is a martial art, a combat sport, a fighting system but I have such a laugh at training and sparring.`,
    author: 'Dan',
  },
]

export default {
  name: 'Home',

  mixins: [page],

  components: {
    Banner,
    ClassDataWrapper,
    SectionDataWrapper,
    Section,
    WrappedSection,
    LocationSection,
    GallerySection,
    LatestPosts,
    Testimonial,
    Carousel,
    Slide,
  },

  data() {
    return {
      pageSlug: 'home',
      testimonials,
    }
  },
}
</script>

<style lang="sass">
@import "~@/stylesheets/base/variables"
@import "~@/stylesheets/helpers/mixins"

.page-home
  ::v-deep .address
    text-align: center
    color: $color-dark
    .sprite
      fill: $color-dark

  .location
    .address
      text-align: left


  .slide
    display: flex
</style>
