<template>
  <div class="page-contact">
    <Banner :image-versions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first BJJ lesson
      <br />
      Get in touch
    </Banner>

    <SectionDataWrapper sectionSlug="contact-intro">
      <template v-slot:default="slotProps">
        <Section class="is-odd">
          <h1 v-html="slotProps.title"></h1>

          <div v-html="slotProps.content"></div>

          <template v-slot:aside>
            <Phone />
            <Email />
          </template>
        </Section>
      </template>
    </SectionDataWrapper>

    <LocationSection className="is-even" />

    <SectionDataWrapper sectionSlug="contact-form">
      <template v-slot:default="slotProps">
        <Section class="is-odd">
          <h2 v-html="slotProps.title"></h2>

          <div v-if="slotProps.content" v-html="slotProps.content"></div>

          <ContactForm
            v-if="!sent"
            :loading="loading"
            v-model="message"
            :errors="errors"
            @submit="onSubmit"
          />

          <p v-else>Thanks. We will get back to you soon.</p>
        </Section>
      </template>
    </SectionDataWrapper>
  </div>
</template>

<script>
import page from '@/mixins/page'
import errorMessages from '@/lib/error_messages'
import {createMessage} from '@/lib/messages'

import Banner from '@/components/Banner'
import ContactForm from '@/components/forms/ContactForm'
import Email from '@/components/Email'
import Phone from '@/components/Phone'
import Section from '@/components/Section'
import SectionDataWrapper from '@/components/SectionDataWrapper'
import LocationSection from '@/components/LocationSection'

const CONTACT_FORM_ID = 24
const CONTACT_FORM_NAME_KEY = 'contact-name'
const CONTACT_FORM_EMAIL_KEY = 'contact-email'
const CONTACT_FORM_SUBJECT_KEY = 'message-subject'
const CONTACT_FORM_MESSAGE_KEY = 'message-body'
const CONTACT_FORM_ERROR_MESSAGE =
  'Could not deliver message at this time. Please try again later.'

const SUCCESS_STATUS = 'mail_sent'
const WPCF7_UNIT_TAG = 'KOBA_CONTACT_FORM'

export default {
  name: 'Contact',

  mixins: [page],

  components: {
    Banner,
    ContactForm,
    Email,
    Phone,
    Section,
    SectionDataWrapper,
    LocationSection,
  },

  data() {
    return {
      pageSlug: 'contact',
      message: {},
      errors: {},
      loading: false,
      sent: false,
    }
  },

  computed: {
    formData() {
      let data = new FormData()

      data.append('fullname', this.message.name)
      data.append('email', this.message.email)
      data.append('message', this.message.message)
      data.append('_wpcf7_unit_tag', WPCF7_UNIT_TAG)

      return data
    },
  },

  methods: {
    onSubmit() {
      this.loading = true

      createMessage(CONTACT_FORM_ID, this.formData).then(({data}) => {
        if (data.status === SUCCESS_STATUS) {
          this.sent = true
        } else {
          alert(CONTACT_FORM_ERROR_MESSAGE)
        }

        this.loading = false
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.page-contact
  ::v-deep .phone
    .number
      color: $color-dark
    .sprite
      fill: $color-dark

  ::v-deep .email
    .address
      color: $color-dark
    .sprite
      fill: $color-dark
</style>
