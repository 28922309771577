<template>
  <ul class="timetable">
    <li v-for="day in daysWithClasses" :key="day.day" class="timetable-day">
      <div class="timetable-day-label timetable-day-label-full">
        {{ day.day }}
      </div>

      <div class="timetable-day-label timetable-day-label-abbreviated">
        {{ day.day | short }}
      </div>

      <ul class="timetable-day-sessions">
        <TimetableSession
          v-for="session in day.classes"
          :key="session.id"
          :types="types"
          :ages="ages"
          :session="session"
          :filter="filter"
          :levels="levels"
        />
      </ul>
    </li>
  </ul>
</template>

<script>
import TimetableSession from './TimetableSession'

export default {
  name: 'Timetable',

  props: {
    classes: {
      type: Array,
      required: true
    },

    days: {
      type: Object,
      required: true
    },

    types: {
      type: Object,
      required: true
    },

    levels: {
      type: Object,
      required: true
    },

    ages: {
      type: Object,
      required: true
    },

    filter: {
      type: Array,
      required: false
    }
  },

  components: {
    TimetableSession
  },

  filters: {
    short(value) {
      return value.substr(0, 3)
    }
  },

  computed: {
    dayShort() {
      return this.day.substr(0, 2)
    },

    daysClasses() {
      return Object.keys(this.days).map((key) => {
        const day = this.days[key].name

        const classes = this.classes.filter((session) => {
          return parseInt(session.acf.day) === parseInt(key)
        })

        return {
          day,
          classes
        }
      })
    },

    daysWithClasses() {
      return this.daysClasses.filter((day) => {
        return day.classes.length
      })
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.timetable
  max-width: 750px
  margin: $pad auto
  text-align: left

  .timetable-day-label-abbreviated
    display: inline-block

  .timetable-day-label-full
    display: none

  .timetable-day-sessions
    display: flex
    flex-direction: column
    align-items: flex-end
    width: 100%
    padding: 0 0 0 $pad / 2

    @include media($media-gt-medium)
      padding: 0 0 0 $pad

  @include media($media-gt-small)
    font-size: 95%
    list-style: none
    padding: 0
    border: 1px solid $color-dark
    border-radius: 2px

    .timetable-day
      display: flex
      list-style: none
      margin: 0
      padding: $pad / 4
      align-items: center
      background: $color-light-section-background

      &:nth-of-type(2n)
        background: $color-dark-section-background


      .timetable-day-label
        min-width: 25px

      .timetable-day-sessions
        list-style: none

  @include media($media-gt-medium)
    font-size: 100%
    .timetable-day
      justify-content: space-between

    .timetable-day-label-abbreviated
      display: none

    .timetable-day-label-full
      display: inline-block
</style>
