import { render, staticRenderFns } from "./SectionDataWrapper.vue?vue&type=template&id=13e715be&scoped=true&"
import script from "./SectionDataWrapper.vue?vue&type=script&lang=js&"
export * from "./SectionDataWrapper.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./SectionDataWrapper.vue?vue&type=style&index=0&id=13e715be&prod&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "13e715be",
  "681beaef"
  
)

export default component.exports