import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=6f7a6970&scoped=true&"
import script from "./Pagination.vue?vue&type=script&lang=js&"
export * from "./Pagination.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Pagination.vue?vue&type=style&index=0&id=6f7a6970&prod&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "6f7a6970",
  "4990824a"
  
)

export default component.exports