<template>
  <div class="aspect-ratio-wrapper" :style="{height: paddingTop}">
    <slot></slot>
  </div>
</template>

<script>
import sizable from '@/mixins/sizable'

export default {
  name: 'AspectRatioWrapper',

  mixins: [sizable],

  props: {
    ratio: {
      type: Array,
      required: true,
    },
  },

  computed: {
    widthRatio() {
      return this.ratio[0]
    },

    heightRatio() {
      return this.ratio[1]
    },

    elHeight() {
      return (this.width / this.widthRatio) * this.heightRatio
    },

    paddingTop() {
      return `${this.elHeight}px`
    },
  },
}
</script>

<style lang="sass" scoped>
.aspect-ratio-wrapper
  position: relative
  width: 100%

  >*
    position: absolute!important
    top: 0
    bottom: 0
    left: 0
    right: 0

  ::deep(.lazy-image)
    position: absolute!important
</style>
