import Vue from 'vue'

import types from './types'

function loading(state) {
  return Vue.set(state, 'activeRequests', state.activeRequests + 1)
}

function loaded(state) {
  return Vue.set(state, 'activeRequests', state.activeRequests - 1)
}

export default {
  [types.REQUEST_PAGE](state) {
    loading(state)
  },

  [types.REQUESTED_PAGE](state) {
    loaded(state)
  },

  [types.RECEIVE_PAGE](state, {readAt, slug, page}) {
    page.__readAt = readAt
    Vue.set(state.pagesBySlug, slug, page)
  },

  [types.REQUEST_SECTION](state) {
    loading(state)
  },

  [types.REQUESTED_SECTION](state) {
    loaded(state)
  },

  [types.RECEIVE_SECTION](state, {readAt, slug, section}) {
    section.__readAt = readAt
    Vue.set(state.sectionsBySlug, slug, section)
  },

  [types.REQUEST_CLASSES](state) {
    loading(state)
  },

  [types.REQUESTED_CLASSES](state) {
    loaded(state)
  },

  [types.RECEIVE_CLASSES](state, {readAt, classes}) {
    Vue.set(state, 'classesReadAt', readAt)
    Vue.set(state, 'classes', classes)
  },

  [types.REQUEST_CLASS_DAYS](state) {
    loading(state)
  },

  [types.REQUESTED_CLASS_DAYS](state) {
    loaded(state)
  },

  [types.RECEIVE_CLASS_DAYS](state, {readAt, classDays}) {
    Vue.set(state, 'classDaysReadAt', readAt)

    classDays.forEach((day) => {
      Vue.set(state.classDaysById, day.id, day)
    })
  },

  [types.REQUEST_CLASS_AGES](state) {
    loading(state)
  },

  [types.REQUESTED_CLASS_AGES](state) {
    loaded(state)
  },

  [types.RECEIVE_CLASS_AGES](state, {readAt, classAges}) {
    Vue.set(state, 'classAgesReadAt', readAt)

    classAges.forEach((age) => {
      Vue.set(state.classAgesById, age.id, age)
    })
  },

  [types.REQUEST_CLASS_TYPES](state) {
    loading(state)
  },

  [types.REQUESTED_CLASS_TYPES](state) {
    loaded(state)
  },

  [types.RECEIVE_CLASS_TYPES](state, {readAt, classTypes}) {
    Vue.set(state, 'classTypesReadAt', readAt)

    classTypes.forEach((type) => {
      Vue.set(state.classTypesById, type.id, type)
    })
  },

  [types.REQUEST_POSTS](state) {
    loading(state)
  },

  [types.REQUESTED_POSTS](state) {
    loaded(state)
  },

  [types.RECEIVE_POSTS](state, {readAt, posts}) {
    Vue.set(state, 'postsReadAt', readAt)

    posts.forEach((post) => {
      Vue.set(state.postsBySlug, post.slug, post)
    })
  },

  [types.REQUEST_POST](state) {
    loading(state)
  },

  [types.REQUESTED_POST](state) {
    loaded(state)
  },

  [types.RECEIVE_POST](state, {readAt, post, slug}) {
    post.__readAt = readAt

    Vue.set(state.postsBySlug, slug, post)
  },

  [types.REQUEST_GALLERY_ITEMS](state) {
    loading(state)
  },

  [types.REQUESTED_GALLERY_ITEMS](state) {
    loaded(state)
  },

  [types.RECEIVE_GALLERY_ITEMS](state, {readAt, items}) {
    Vue.set(state, 'galleryItemsReadAt', readAt)
    Vue.set(state, 'galleryItems', items)
  },

  [types.REQUEST_CLASS_LEVELS](state) {
    loading(state)
  },

  [types.REQUESTED_CLASS_LEVELS](state) {
    loaded(state)
  },

  [types.RECEIVE_CLASS_LEVELS](state, {readAt, classLevels}) {
    Vue.set(state, 'classLevelsReadAt', readAt)

    classLevels.forEach((age) => {
      Vue.set(state.classLevelsById, age.id, age)
    })
  },

  [types.REQUEST_INSTRUCTORS](state) {
    loading(state)
  },

  [types.REQUESTED_INSTRUCTORS](state) {
    loaded(state)
  },

  [types.RECEIVE_INSTRUCTORS](state, {readAt, instructors}) {
    Vue.set(state, 'instructorsReadAt', readAt)
    Vue.set(state, 'instructors', instructors)
  }
}
