<template>
  <div class="page-about">
    <Banner :imageVersions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <WrappedSection slug="about-koba-bjj" className="is-odd" />

    <WrappedSection id="what_is_bjj" slug="jiu-jits-who" className="is-even" />
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import WrappedSection from '@/components/WrappedSection'

export default {
  name: 'About',

  mixins: [page],

  components: {
    Banner,
    WrappedSection,
  },

  data() {
    return {
      pageSlug: 'about',
    }
  },
}
</script>

<style lang="sass" scoped>
.page-about
</style>
