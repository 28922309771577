<template>
  <div class="page-privacy">
    <Banner>
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <Container>
      <h1>{{ title }}</h1>
      <div v-html="content"></div>
    </Container>
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import Container from '@/components/Container'

export default {
  name: 'Privacy',

  mixins: [page],

  components: {
    Banner,
    Container,
  },

  data() {
    return {
      pageSlug: 'privacy',
    }
  },
}
</script>

<style lang="sass">
.page-privacy
</style>
