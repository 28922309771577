<template>
  <button class="button">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button'
}
</script>

<style lang="sass" scoped>
@import "../../../stylesheets/base/variables"
@import "../../../stylesheets/helpers/mixins"

.button
  background: $color-light
  color: $color-dark
  border: 1px solid $color-dark
  border-radius: 2px
  padding: $pad / 2
  font-weight: bold
</style>
