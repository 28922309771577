var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Section',{staticClass:"post-preview",scopedSlots:_vm._u([(_vm.featuredImageUrl)?{key:"aside",fn:function(){return [_c('LazyImg',{attrs:{"url":_vm.featuredImageUrl,"description":_vm.title}})]},proxy:true}:null],null,true)},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('div',[_vm._v("Posted on: "+_vm._s(_vm.formattedDate))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.excerpt)}}),_c('router-link',{attrs:{"to":{
        name: 'article',
        params: {
          year: _vm.year,
          month: _vm.month,
          day: _vm.day,
          slug: _vm.slug
        }
      }}},[_vm._v("\n      Read more...\n    ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }