<template>
  <Timetable
    v-if="showTimetable"
    :classes="sortedClasses"
    :types="classTypesById"
    :days="classDaysById"
    :ages="classAgesById"
    :levels="classLevelsById"
    :filter="filter"
  />

  <div class="no-classes" v-else>
    Classes are coming soon.

    <br />
    <br />

    <RouterLink :to="{name: 'contact'}">Get in touch</RouterLink> for more
    information.
  </div>
</template>

<script>
import Timetable from '@/components/Timetable'

import data_wrapper from '@/mixins/data_wrapper'

import {mapState, mapActions} from 'vuex'

export default {
  name: 'ClassDataWrapper',

  mixins: [data_wrapper],

  props: {
    filter: {
      type: Array,
      required: false
    }
  },

  components: {
    Timetable
  },

  computed: {
    ...mapState([
      'classes',
      'classLevelsById',
      'classLevelsReadAt',
      'classDaysById',
      'classTypesById',
      'classesReadAt',
      'classDaysReadAt',
      'classTypesReadAt',
      'classAgesById',
      'classAgesReadAt'
    ]),

    showTimetable() {
      return this.filteredClasses.length
    },

    loading() {
      return (
        !this.classesReadAt ||
        !this.classDaysReadAt ||
        !this.classTypesReadAt ||
        !this.classLevelsReadAt ||
        !this.classAgesReadAt
      )
    },

    filteredClasses() {
      if (this.loading) {
        return []
      }

      if (this.filter) {
        const ageIds = Object.keys(this.classAgesById)
          .map((key) => {
            return this.classAgesById[key]
          })
          .filter((age) => {
            return this.filter.includes(age.name)
          })
          .map((age) => {
            return parseInt(age.id)
          })

        return this.classes.filter((session) => {
          return ageIds.includes(session.acf.age)
        })
      } else {
        return this.classes
      }
    },

    sortedClasses() {
      const classesCopy = this.filteredClasses.slice()

      return classesCopy.sort((a, b) => {
        const aTime = a.acf.start_time
        const bTime = b.acf.start_time

        return aTime.localeCompare(bTime)
      })
    },

    shouldGetClasses() {
      return this.resourceStale(this.classesReadAt)
    },

    shouldGetClassDays() {
      return this.resourceStale(this.classDaysReadAt)
    },

    shouldGetClassLevels() {
      return this.resourceStale(this.classLevelsReadAt)
    },

    shouldGetClassTypes() {
      return this.resourceStale(this.classTypesReadAt)
    },

    shouldGetClassAges() {
      return this.resourceStale(this.classAgesReadAt)
    }
  },

  methods: {
    ...mapActions([
      'readClasses',
      'readClassTypes',
      'readClassDays',
      'readClassAges',
      'readClassLevels'
    ])
  },

  serverPrefetch() {
    return Promise.all([
      this.readClasses(),
      this.readClassTypes(),
      this.readClassDays(),
      this.readClassLevels(),
      this.readClassAges()
    ])
  },

  mounted() {
    if (this.shouldGetClasses) {
      this.readClasses()
    }

    if (this.shouldGetClassTypes) {
      this.readClassTypes()
    }

    if (this.shouldGetClassLevels) {
      this.readClassLevels()
    }

    if (this.shouldGetClassDays) {
      this.readClassDays()
    }

    if (this.shouldGetClassAges) {
      this.readClassAges()
    }
  }
}
</script>

<style lang="sass" scoped>
.class-data-wrapper
</style>
