import Axios from 'axios'

const host = process.env.VUE_APP_API_HOST_CLIENT

function formUrl(id) {
  return `${host}/wp-json/contact-form-7/v1/contact-forms/${id}/feedback`
}

export function createMessage(formId, data) {
  const url = formUrl(formId)
  const method = 'post'
  const config = {headers: {'Content-Type': 'multipart/form-data'}}

  return Axios.request({
    url,
    method,
    data,
    config
  })
}
