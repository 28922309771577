<template>
  <div class="page-blog">
    <Banner :image-versions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <PostPreview
      v-for="(post, i) in posts"
      :post="post"
      :key="post.id"
      :class="{'is-odd': !i % 2, 'is-even': !!i % 2}"
    />
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import Container from '@/components/Container'
import PostPreview from '@/components/Blog/PostPreview'

import page from '@/mixins/page'

import {mapState, mapActions} from 'vuex'

export default {
  name: 'Blog',

  mixins: [page],

  components: {
    Banner,
    PostPreview,
  },

  data() {
    return {
      pageSlug: 'blog',
    }
  },

  computed: {
    ...mapState(['postsBySlug', 'postsReadAt']),

    posts() {
      return Object.keys(this.postsBySlug).map((key) => {
        return this.postsBySlug[key]
      })
    },
  },

  methods: {
    ...mapActions(['readPosts']),
  },

  serverPrefetch() {
    this.readPosts()
  },

  mounted() {
    if (!this.postsReadAt) {
      this.readPosts()
    }
  },
}
</script>

<style lang="sass">
.page-blog
  .posts-list
    list-style: none
    margin: 0
    padding: 0

    .posts-list-item
      padding: 10px 0
</style>
