<template>
  <footer class="foot">
    <div class="row top">
      <div class="col">
        <p>
          &copy;
          <a href="https://koba-bjj.club" class="p-name p-url">Koba BJJ</a> 2020
          - Brazilian Jiu-jitsu, Dorking, Surrey
        </p>
      </div>

      <div class="col middle h-card">
        <Address />
        <Phone />
        <Email />
      </div>

      <SocialLinks class="col" />
    </div>

    <div class="row bottom">
      <div>
        <ul class="footer-links">
          <li>
            <router-link :to="{name: 'blog'}">Blog</router-link>
          </li>

          <li>
            <router-link :to="{name: 'faq'}">FAQ</router-link>
          </li>

          <li>
            <router-link :to="{name: 'privacy'}">Privacy policy</router-link>
          </li>
        </ul>
      </div>

      <div></div>

      <div>
        <a
          href="http://nathansplace.co.uk"
          target="_blank"
          title="Website by NathanG"
        >
          <Sprite name="nathansplace" class="nathansplace" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import Address from '@/components/Address'
import Email from '@/components/Email'
import LazyImg from '@/components/LazyImg'
import Phone from '@/components/Phone'
import SocialLinks from '@/components/SocialLinks'
import Sprite from '@/components/Sprite'

export default {
  name: 'Foot',

  components: {
    Address,
    Email,
    LazyImg,
    Phone,
    SocialLinks,
    Sprite,
  },
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.foot
  padding: $pad
  background: $color-dark
  color: $color-light
  text-align: center
  margin-top: auto
  font-size: 12px

  .footer-links
    @include list

    display: flex

    justify-content: space-between

    @include media($media-gt-big)
      justify-content: origin

    li
      padding: 0 5px

      a
        padding: 0 2pxz

  .row
    max-width: $max-width
    margin: auto

  p
    color: $color-light

  > div
    align-items: top

  a
    color: $color-light
    text-decoration: none

    a:hover
      text-decoration: underline

  > div
    padding: $pad / 2

  .nathansplace
    width: 25px
    height: 28px
    opacity: 0.3

  .row
    &.top
      .col
        flex: 1

    &>*
      padding: $pad / 2

    @include media($media-gt-big)
      display: flex
      justify-content: space-between
      align-items: flex-start

    &.bottom
      align-items: center
      padding-bottom: 0

  .middle
    &> *
      padding-bottom: $pad / 2
</style>
