import Axios from 'axios'

/**
 * Prepend api host url to a path
 *
 * @param {string} url - a request path
 *
 * @returns {string}
 */
function prependHost(url) {
  const vueTarget = process.env.VUE_APP_TARGET
  const apiHost = process.env[`VUE_APP_API_HOST_${vueTarget.toUpperCase()}`]
  const urlBase = process.env['VUE_APP_API_PATH']

  return `${apiHost}${urlBase}${url}`
}

/**
 * Bridges to axios request, and sets some request defaults
 *
 * @param {string} method - a request method
 * @param {string} url - a request path relative to root
 * @param {object} data - request body as object
 *
 * @returns {promise}
 */
export function request(method, url, data = {}) {
  return Axios.request({
    url: prependHost(url),
    method: method,
    data: data
  })
}
