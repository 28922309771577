import {request} from '@/lib/request'

function classTypesPath() {
  return `/class_types`
}

export function readClassTypes() {
  return request('get', classTypesPath()).then(({data}) => {
    return data
  })
}
