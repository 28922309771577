export default {
  props: {
    value: {}
  },

  computed: {
    resource: {
      set(newValue) {
        this.$emit('input', newValue)
      },

      get() {
        return this.value
      }
    }
  }
}
