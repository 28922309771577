<template>
  <div class="social-links">
    <ul>
      <li>
        <a href="https://www.instagram.com/danbbjj/"  aria-label="Koba BJJ on Instagram" target="_blank">
          <Sprite class="social-icon" name="instagram" />
        </a>
      </li>

      <li>
        <a href="https://en-gb.facebook.com/Bjjdorking/" aria-label="Koba BJJ on Facebook" target="_blank">
          <Sprite class="social-icon" name="facebook" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import Sprite from '@/components/Sprite'

export default {
  name: 'SocialLinks',

  components: {
    Sprite
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.social-links
  display: inline-block
  width: auto

  ul
    list-style: none
    display: flex
    margin: 0
    padding: 0
    justify-content: flex-end

    li
      padding-right: $pad / 4

      .social-icon
        fill: $color-light
        width: 22px
        height: 22px
        margin: 0
</style>
