import { render, staticRenderFns } from "./AspectRatioWrapper.vue?vue&type=template&id=51bd89fb&scoped=true&"
import script from "./AspectRatioWrapper.vue?vue&type=script&lang=js&"
export * from "./AspectRatioWrapper.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./AspectRatioWrapper.vue?vue&type=style&index=0&id=51bd89fb&prod&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "51bd89fb",
  "7ab22bea"
  
)

export default component.exports