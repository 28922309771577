<template>
  <Container class="section" :class="{'with-aside': withAside}">
    <div class="inner">
      <div class="column text">
        <slot />
      </div>

      <div v-if="withAside" class="column aside">
        <slot name="aside" />
      </div>
    </div>
  </Container>
</template>

<script>
import Container from '@/components/Container'

export default {
  name: 'Section',

  components: {
    Container,
  },

  computed: {
    withAside() {
      return !!this.$slots.aside
    },
  },
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.section
  .inner
    @include media($media-gt-big)
      display: flex
      align-items: center


  .column
    padding: $pad / 2

    ::v-deep a
      color: $color-highlight

      &:hover
        text-decoration: underline

    @include media($media-gt-medium)
      padding: $pad

  .text
    order: 1
    text-align: center
    width: 100%

    ::v-deep h1
      text-align: center

    ::v-deep h2
      text-align: center

    ::v-deep h3
      text-align: center

  .aside
    order: 2

    ::v-deep .lazy-img
      text-align: center

      img
        &.is-portrait
          max-width: 300px
        &.is-landscape
          max-width: 400px

  &.with-aside
    .text
      text-align: left

      ::v-deep h1
        text-align: left

      ::v-deep h2
        text-align: left

      ::v-deep h3
        text-align: left

      @include media($media-gt-big)
        width: 50%

      @include media($media-gt-very-big)
        width: 66%

    .aside
      @include media($media-gt-big)
        width: 50%

      @include media($media-gt-very-big)
        width: 33%

    @include media($media-gt-big)
      ::v-deep .timetable
        margin: $pad 0

  background: $color-light-section-background
  color: $color-light-section-text

  &.is-even
    background: $color-dark-section-background
    color: $color-dark-section-text

    .text
      order: 2

    .aside
      order: 1
</style>
