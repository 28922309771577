<template>
  <AspectRatioWrapper :ratio="[6, 4]">
    <ResponsiveImage
      class="gallery-item"
      @click.native="onItemClick"
      :image-versions="featuredImageVersions"
      :description="description"
      :width="width"
    />
  </AspectRatioWrapper>
</template>

<script>
import ResponsiveImage from '@/components/ResponsiveImage'

import sizable from '@/mixins/sizable'
import AspectRatioWrapper from '../AspectRatioWrapper.vue'

export default {
  name: 'GalleryItem',

  mixins: [sizable],

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  components: {
    ResponsiveImage,
    AspectRatioWrapper,
  },

  computed: {
    url() {
      return this.item._embedded['wp:featuredmedia']['0'].source_url
    },

    description() {
      return (
        this.item.title.rendered ||
        this.item._embedded['wp:featuredmedia']['0'].alt_text
      )
    },

    embedded() {
      return this.item._embedded || {}
    },

    featuredMedia() {
      return this.embedded['wp:featuredmedia'] || {}
    },

    featuredImage() {
      return this.featuredMedia['0'] || {}
    },

    featuredImageMediaDetails() {
      return this.featuredImage.media_details || {}
    },

    featuredImageSizes() {
      return this.featuredImageMediaDetails.sizes || {}
    },

    featuredImageVersions() {
      return Object.keys(this.featuredImageSizes).map((key) => {
        const size = this.featuredImageSizes[key]

        return {
          width: size.width,
          url: size.source_url,
        }
      })
    },
  },

  methods: {
    onItemClick() {
      this.$emit('selected', {url: this.url, description: this.description})
    },
  },
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.gallery-item
  padding: $pad / 2
  cursor: pointer
  width: 100%
  height: 100%

  ::v-deep .lazy-img
    @include media($media-gt-big)
      padding: $pad
</style>
