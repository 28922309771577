import { render, staticRenderFns } from "./KidsClasses.vue?vue&type=template&id=1867eacc&scoped=true&"
import script from "./KidsClasses.vue?vue&type=script&lang=js&"
export * from "./KidsClasses.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./KidsClasses.vue?vue&type=style&index=0&id=1867eacc&prod&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1867eacc",
  "19bbb501"
  
)

export default component.exports