export default {
  REQUEST_PAGE: 'REQUEST_PAGE',
  REQUESTED_PAGE: 'REQUESTED_PAGE',
  RECEIVE_PAGE: 'RECEIVE_PAGE',

  REQUEST_SECTION: 'REQUEST_SECTION',
  REQUESTED_SECTION: 'REQUESTED_SECTION',
  RECEIVE_SECTION: 'RECEIVE_SECTION',

  REQUEST_CLASSES: 'REQUEST_CLASSES',
  REQUESTED_CLASSES: 'REQUESTED_CLASSES',
  RECEIVE_CLASSES: 'RECEIVE_CLASSES',

  REQUEST_CLASS_DAYS: 'REQUEST_CLASS_DAYS',
  REQUESTED_CLASS_DAYS: 'REQUESTED_CLASS_DAYS',
  RECEIVE_CLASS_DAYS: 'RECEIVE_CLASS_DAYS',

  REQUEST_CLASS_AGES: 'REQUEST_CLASS_AGES',
  REQUESTED_CLASS_AGES: 'REQUESTED_CLASS_AGES',
  RECEIVE_CLASS_AGES: 'RECEIVE_CLASS_AGES',

  REQUEST_CLASS_TYPES: 'REQUEST_CLASS_TYPES',
  REQUESTED_CLASS_TYPES: 'REQUESTED_CLASS_TYPES',
  RECEIVE_CLASS_TYPES: 'RECEIVE_CLASS_TYPES',

  REQUEST_CLASS_LEVELS: 'REQUEST_CLASS_LEVELS',
  REQUESTED_CLASS_LEVELS: 'REQUESTED_CLASS_LEVELS',
  RECEIVE_CLASS_LEVELS: 'RECEIVE_CLASS_LEVELS',

  REQUEST_POSTS: 'REQUEST_POSTS',
  REQUESTED_POSTS: 'REQUESTED_POSTS',
  RECEIVE_POSTS: 'RECEIVE_POSTS',

  REQUEST_POST: 'REQUEST_POST',
  REQUESTED_POST: 'REQUESTED_POST',
  RECEIVE_POST: 'RECEIVE_POST',

  REQUEST_GALLERY_ITEMS: 'REQUEST_GALLERY_ITEMS',
  REQUESTED_GALLERY_ITEMS: 'REQUESTED_GALLERY_ITEMS',
  RECEIVE_GALLERY_ITEMS: 'RECEIVE_GALLERY_ITEMS',

  REQUEST_INSTRUCTORS: 'REQUEST_INSTRUCTORS',
  REQUESTED_INSTRUCTORS: 'REQUESTED_INSTRUCTORS',
  RECEIVE_INSTRUCTORS: 'RECEIVE_INSTRUCTORS'
}
