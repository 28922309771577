<template>
  <form class="contact-form" @submit.prevent="onSubmit">
    <Input
      placeholder="Name"
      v-model="resource.name"
      @input="$v.resource.name.$touch()"
      :error="nameError"
    />

    <Input
      placeholder="Email"
      v-model="resource.email"
      @input="$v.resource.email.$touch()"
      :error="emailError"
    />

    <TextArea
      placeholder="Message"
      v-model="resource.message"
      @input="$v.resource.message.$touch()"
      :error="messageError"
    />

    <Button>Send</Button>

    <Loader :loading="loading" />
  </form>
</template>

<script>
import form from '@/mixins/form'

import {errorMessages} from '@/lib/error_messages'
import {required, email} from 'vuelidate/lib/validators'

export default {
  name: 'ContactForm',

  mixins: [form],

  computed: {
    ...errorMessages(['name', 'email', 'message'])
  },

  validations: {
    resource: {
      name: {required},
      email: {required, email},
      message: {required}
    }
  }
}
</script>

<style lang="sass" scoped>
.contact-form
  max-width: 600px
  margin: auto
  position: relative
</style>
