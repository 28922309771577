import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

Vue.use(Vuex)

export function createStore() {
  /* elsint-disabel no-new */
  return new Vuex.Store({
    actions,
    getters,
    mutations,
    state: {
      activeRequests: 0,
      pagesBySlug: {},
      sectionsBySlug: {},
      classes: [],
      classesReadAt: null,
      classDaysById: {},
      classDaysReadAt: null,
      classAgesById: {},
      classAgesReadAt: null,
      classTypesById: {},
      classTypesReadAt: null,
      classLevelsById: {},
      classLevelsReadAt: null,
      instructors: [],
      instructorsReadAt: null,
      postsBySlug: {},
      postsReadAt: null,
      galleryItems: [],
      galleryItemsReadAt: null
    }
  })
  /* elsint-enable no-new */
}
