<template>
  <div class="error">
    {{ error }}
  </div>
</template>

<script>
export default {
  name: 'Error',

  props: {
    error: String
  }
}
</script>

<style lang="sass">
.error
  color: red
  text-align: left
  font-size: 12px
  min-height: 20px
</style>
