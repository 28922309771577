<template>
  <div class="page-instructors">
    <Banner :image-versions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <Instructor
      v-for="(instructor, i) in instructors"
      :instructor="instructor"
      :key="i"
      :class="{'is-odd': !i % 2, 'is-even': i % 2}"
    />
  </div>
</template>

<script>
import Banner from '@/components/Banner'

import Instructor from './Instructor'

import page from '@/mixins/page'

import {mapState, mapActions} from 'vuex'

export default {
  name: 'Instructors',

  mixins: [page],

  components: {
    Banner,
    Instructor,
  },

  data() {
    return {
      pageSlug: 'instructors',
    }
  },

  computed: {
    ...mapState(['instructors', 'instructorsReadAt']),
  },

  methods: {
    ...mapActions(['readInstructors']),
  },

  serverPrefetch() {
    this.readInstructors()
  },

  mounted() {
    if (!this.instructorsReadAt) {
      this.readInstructors()
    }
  },
}
</script>

<style lang="sass" scoped>
.page-instructors
</style>
