<template>
  <SectionDataWrapper class="location-section" sectionSlug="location">
    <template v-slot:default="slotProps">
      <Section :class="className">
        <h2 v-html="slotProps.title"></h2>

        <div v-html="slotProps.content"></div>

        <Address class="location-address" />

        <p v-if="contactLink">
          See our
          <RouterLink title="contact" :to="{name: 'contact'}">contact</RouterLink> information
          for more details.
        </p>

        <template v-slot:aside>
          <RouterLink title="contact" aria-label="contact" :to="{name: 'contact'}">
            <Map />
          </RouterLink>
        </template>
      </Section>
    </template>
  </SectionDataWrapper>
</template>

<script>
import Map from '@/components/Map'
import Section from '@/components/Section'
import SectionDataWrapper from '@/components/SectionDataWrapper'
import Address from '@/components/Address'

export default {
  name: 'LocationSection',

  props: {
    className: {
      type: String,
      required: true
    },

    contactLink: {
      type: Boolean,
      default() {
        return false
      }
    }
  },

  components: {
    Address,
    Map,
    Section,
    SectionDataWrapper
  }
}
</script>

<style lang="sass" scoped>
@import "~@/stylesheets/base/variables"
@import "~@/stylesheets/helpers/mixins"

.location-section
  ::v-deep .address
    text-align: center
    color: $color-dark
    .sprite
      fill: $color-dark
</style>
