import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=37e9a7d0&scoped=true&"
import script from "./Loader.vue?vue&type=script&lang=js&"
export * from "./Loader.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Loader.vue?vue&type=style&index=0&id=37e9a7d0&prod&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "37e9a7d0",
  "156962c4"
  
)

export default component.exports