/**
 * Page mixin for view components
 */
import {decodeHtml} from '@/filters'
import {mapState, mapActions} from 'vuex'

import data_wrapper from '@/mixins/data_wrapper'

const LOADING_TEXT = 'Loading...'

export default {
  mixins: [data_wrapper],

  metaInfo() {
    return {
      title: decodeHtml(this.title),
      meta: this.metaTags || []
    }
  },

  computed: {
    ...mapState(['pagesBySlug']),

    content() {
      return this.page.content ? this.page.content.rendered : LOADING_TEXT
    },

    page() {
      return this.pagesBySlug[this.pageSlug] || {}
    },

    acf() {
      return this.page.acf || {}
    },

    title() {
      return this.page.title
        ? `${this.page.title.rendered} | Koba BJJ`
        : LOADING_TEXT
    },

    pageStale() {
      return this.resourceStale(this.page.__readAt)
    },

    shouldGetPage() {
      return !this.pagesBySlug[this.pageSlug] || this.pageStale
    },

    embedded() {
      return this.page._embedded || {}
    },

    featuredMedia() {
      return this.embedded['wp:featuredmedia'] || {}
    },

    featuredImage() {
      return this.featuredMedia['0'] || {}
    },

    featuredImageMediaDetails() {
      return this.featuredImage.media_details || {}
    },

    featuredImageSizes() {
      return this.featuredImageMediaDetails.sizes || {}
    },

    featuredImageVersions() {
      return Object.keys(this.featuredImageSizes).map((key) => {
        const size = this.featuredImageSizes[key]

        return {
          width: size.width,
          url: size.source_url
        }
      })
    },

    featuredImageUrl() {
      return this.featuredImage.source_url || null
    }
  },

  methods: {
    ...mapActions(['readPage']),

    getData() {
      const slug = this.pageSlug
      return this.readPage({slug})
    },

    updateData() {
      if (this.shouldGetPage) {
        this.getData()
      }
    }
  },

  serverPrefetch() {
    return this.getData()
  },

  mounted() {
    this.updateData()
  },

  watch: {
    $route() {
      this.updateData()
    }
  }
}
