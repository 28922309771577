<template>
  <div class="loader">
    <transition name="fade">
      <div v-if="loading" :class="{blackout: 1, 'is-loading': loading}">
        <div>Loading...</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Loader',

  props: {
    loading: Boolean
  }
}
</script>

<style lang="sass">
.loader
  .blackout
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    background: rgba(0,0,0, 0.2)
    display: none
    align-items: center
    justify-content: center

    >div
      background: #fff
      padding: 30px

    &.is-loading
      display: flex
</style>
