<template>
  <svg class="sprite">
    <use-svg :name="name"></use-svg>
    <slot></slot>
  </svg>
</template>

<script>
import UseSvg from '@/components/UseSvg.vue'

export default {
  name: 'Sprite',

  props: {
    name: String
  },

  components: {
    UseSvg
  }
}
</script>
