<template>
  <div class="page-membership">
    <Banner :image-versions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <WrappedSection slug="membership" className="is-odd" />

    <LocationSection contactLink className="is-even" />

    <GallerySection className="is-odd" />
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import GallerySection from '@/components/GallerySection'
import LocationSection from '@/components/LocationSection'
import WrappedSection from '@/components/WrappedSection'

export default {
  name: 'Membership',

  mixins: [page],

  data() {
    return {
      pageSlug: 'membership',
    }
  },

  components: {
    Banner,
    GallerySection,
    LocationSection,
    WrappedSection,
  },
}
</script>

<style lang="sass" scoped>
.page-membership
</style>
