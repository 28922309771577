<template>
  <Fragment>
    <slot :posts="posts" :lastPost="lastPost"></slot>
  </Fragment>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import data_wrapper from '@/mixins/data_wrapper'

import {Fragment} from 'vue-fragment'

export default {
  name: 'PostDataWrapper',

  mixins: [data_wrapper],

  components: {
    Fragment
  },

  computed: {
    ...mapState(['postsBySlug', 'postsReadAt']),

    posts() {
      return Object.keys(this.postsBySlug).map((key) => {
        return this.postsBySlug[key]
      })
    },

    lastPost() {
      return this.posts[0] || {}
    }
  },

  methods: {
    ...mapActions(['readPosts'])
  },

  serverPrefetch() {
    this.readPosts()
  },

  mounted() {
    if (!this.postsReadAt) {
      this.readPosts()
    }
  }
}
</script>
