<template>
  <div class="page-classes">
    <Banner :image-versions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <WrappedSection slug="adult-classes-preview" className="is-odd">
      <RouterLink :to="{name: 'classes.adults'}">Adult classes</RouterLink>
    </WrappedSection>

    <WrappedSection slug="kids-classes-preview" className="is-even">
      <RouterLink :to="{name: 'classes.kids'}">Kids classes</RouterLink>
    </WrappedSection>

    <WrappedSection slug="teens-classes-preview" className="is-odd">
      <RouterLink :to="{name: 'classes.teens'}">Teens classes</RouterLink>
    </WrappedSection>

    <WrappedSection slug="private-classes" className="is-even" />

    <WrappedSection slug="membership" className="is-odd" />

    <LocationSection className="is-even" />
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import WrappedSection from '@/components/WrappedSection'
import LocationSection from '@/components/LocationSection'

export default {
  name: 'Classes',

  mixins: [page],

  components: {
    Banner,
    LocationSection,
    WrappedSection,
  },

  data() {
    return {
      pageSlug: 'classes',
    }
  },
}
</script>

<style lang="sass">
.page-classes
</style>
