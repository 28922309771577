import { render, staticRenderFns } from "./GallerySection.vue?vue&type=template&id=33ac9a9f&scoped=true&"
import script from "./GallerySection.vue?vue&type=script&lang=js&"
export * from "./GallerySection.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./GallerySection.vue?vue&type=style&index=0&id=33ac9a9f&prod&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "33ac9a9f",
  "17028abe"
  
)

export default component.exports