<template>
  <nav class="navigation" :class="{'is-active': active}">
    <ul class="navigation-list">
      <li v-for="item in items" :key="item.name" class="navigation-list-item">
        <router-link
          :class="item.name.toLowerCase()"
          :to="item.target"
          @click.native="close"
          :title="item.title"
          exact
        >
          {{ item.name }}
        </router-link>

        <ul v-if="item.children" class="sub-menu-list">
          <li
            v-for="child in item.children"
            :key="child.name"
            class="sub-menu-list-item"
          >
            <router-link
              :class="child.name.toLowerCase()"
              :to="child.target"
              @click.native="close"
              :title="child.title"
            >
              {{ child.name }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
function navItem(name, title, route, children = false) {
  return {
    name,
    title,
    target: {name: route},
    children: children,
  }
}

export default {
  name: 'Navigation',

  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      items: [
        navItem('Home', 'Home', 'home'),
        navItem(
          'About',
          'About',
          'about' /* , [
          navItem('Instructors', 'Instructors', 'about.instructors')
        ]*/
        ),
        navItem('Classes', 'Classes', 'classes', [
          navItem('Adults', 'Adults classes', 'classes.adults'),
          navItem('Teens', 'Teens classes', 'classes.teens'),
          navItem('Kids', 'Kids classes', 'classes.kids'),
        ]),
        navItem('Timetable', 'Timetable', 'timetable'),
        navItem('Membership', 'Membership', 'membership'),
        navItem('FAQ', 'FAQ', 'faq'),
        navItem('Contact', 'Contact', 'contact'),
      ],
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.navigation
  display: flex
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 900
  background: $color-dark
  transform: translateX(100%)
  transition: opacity, transform
  transition-duration: $transition-duration
  transition-easing: $transition-easing
  opacity: 0
  align-items: center

  &.is-active
    transform: translateX(0%)
    opacity: 1

  @include media($media-gt-big)
    position: relative
    transform: translateX(0%)
    opacity: 1

    .home
      display: none

  .close
    @include media($media-gt-big)
      display: none

  ul
    list-style: none
    display: inline-block
    width: 100%
    padding: 0
    position: relative

    @include media($media-gt-big)
      margin: auto 0 0 0
      width: auto

  li
    font-family: $font-main
    font-weight: bold
    text-align: center
    padding: $pad / 2
    position: relative

    @include media($media-gt-big)
      display: inline-block
      padding: 0 $pad / 2

      &:last-of-type
        padding-right: 0

    a
      text-decoration: none
      color: $color-light
      transition: color border-color
      transition-duration: $transition-duration
      transtion-easing: $transition-easing
      border-bottom: 2px solid
      border-color: transparent

      &:hover
        color: $color-highlight
        border-color: $color-highlight

      &.router-link-active
        color: $color-highlight

  .sub-menu-list
    background: $color-light
    a
      color: $color-dark

    @include media($media-gt-big)
      position: absolute
      top: 100%
      left: 0
      min-width: 120px
      padding: $pad / 4
      display: none

  .sub-menu-list-item
    padding: $pad / 4
    display: block

    &:last-of-type
      padding-right: $pad / 4

  .navigation-list-item
    @include media($media-gt-big)
      &:hover
        > .sub-menu-list
          display: inline-block
</style>
