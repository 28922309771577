import {request} from '@/lib/request'

function classAgesPath() {
  return `/class_ages`
}

export function readClassAges() {
  return request('get', classAgesPath()).then(({data}) => {
    return data
  })
}
