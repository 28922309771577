import {request} from '@/lib/request'

function classLevlesPath() {
  return `/class_levels`
}

export function readClassLevels() {
  return request('get', classLevlesPath()).then(({data}) => {
    return data
  })
}
