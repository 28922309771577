<template>
  <div class="page-kids-classes">
    <Banner :image-versions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first kids BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <WrappedSection slug="kids-classes" className="is-odd">
      <RouterLink to="/about#what_is_bjj">What is bjj?</RouterLink>
    </WrappedSection>

    <Section class="is-even">
      <h2>Kids BJJ classes timetable</h2>

      <ClassDataWrapper
        :filter="['Kids 5-8yrs', 'Kids 9-12yrs', 'Kids 5-12yrs']"
      />
    </Section>

    <LocationSection className="is-odd" />
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import ClassDataWrapper from '@/components/ClassDataWrapper'
import Section from '@/components/Section'
import WrappedSection from '@/components/WrappedSection'
import LocationSection from '@/components/LocationSection'

export default {
  name: 'KidsClasses',

  mixins: [page],

  data() {
    return {
      pageSlug: 'kids-classes',
    }
  },

  components: {
    Banner,
    ClassDataWrapper,
    Section,
    WrappedSection,
    LocationSection,
  },
}
</script>

<style lang="sass" scoped>
.page-kids-classes
</style>
