<template>
  <div class="phone">
    <a :href="`tel:${number}`" class="p-tel contact-link" aria-label="Phone">
      <Sprite name="phone" class="phone-icon" />
      <div class="number value">
        {{ number }}
      </div>
    </a>
  </div>
</template>

<script>
import Sprite from '@/components/Sprite'

const phone = process.env.VUE_APP_CONTACT_PHONE

export default {
  name: 'Phone',

  data() {
    return {
      number: phone
    }
  },

  components: {
    Sprite
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.phone
  .contact-link
    display: flex
    align-items: center
    justify-content: center

  .phone-icon
    margin-right: 10px
    width: 22px
    height: 22px
    fill: $color-light

  a
    text-decoration: none
    color: $color-light
</style>
