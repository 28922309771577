<template>
  <Section class="latest-post">
    <h2>Latest news</h2>

    <div class="title" v-html="title"></div>

    <div class="excerpt" v-html="excerpt"></div>

    <RouterLink
      :to="{
        name: 'article',
        params: {year: year, month: month, day: day, slug: slug}
      }"
    >
      More {{ title }}
    </RouterLink>

    <template v-slot:aside v-if="hasFeaturedImage">
      <LazyImg :url="featuredImageUrl" :description="title" />
    </template>
  </Section>
</template>

<script>
import wp_post from '@/mixins/wp_post'

import Section from '@/components/Section'
import LazyImg from '@/components/LazyImg'

export default {
  name: 'LatestPost',

  mixins: [wp_post],

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  components: {
    LazyImg,
    Section
  }
}
</script>

<style lang="sass" scoped>
@import '~@/stylesheets/base/variables'
@import '~@/stylesheets/helpers/mixins'

.latest-post
  .title
    font-size: 22px
    font-family: $font-headings
</style>
