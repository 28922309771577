import Vue from 'vue'
import App from './App.vue'
import {sync} from 'vuex-router-sync'
import VueGtag from 'vue-gtag'

import {createRouter} from './router'
import {createStore} from './store'

Vue.config.productionTip = false

export function createApp(context) {
  const router = createRouter()
  const store = createStore()

  sync(store, router)

  if (process.env.NODE_ENV === 'production') {
    Vue.use(
      VueGtag,
      {
        config: {id: process.env.VUE_APP_GTAGS_ID}
      },
      router
    )
  }

  /* eslint-disable no-new */
  const app = new Vue({
    router,
    store,
    render: (h) => h(App)
  })
  /* eslint-enable no-new */

  return {app, router, store}
}
