import { render, staticRenderFns } from "./AdultsClasses.vue?vue&type=template&id=72a341ba&scoped=true&"
import script from "./AdultsClasses.vue?vue&type=script&lang=js&"
export * from "./AdultsClasses.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./AdultsClasses.vue?vue&type=style&index=0&id=72a341ba&prod&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "72a341ba",
  "30d9adf5"
  
)

export default component.exports