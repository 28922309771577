<template>
  <div class="section-data-wrapper" :class="{'is-loading': loading}">
    <p v-if="loading">Loading...</p>

    <slot
      v-else
      :title="title"
      :content="content"
      :featuredImageVersions="featuredImageVersions"
    />
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

const LOADING_TEXT = 'Loading...'

export default {
  name: 'SectionDataWrapper',

  props: {
    sectionSlug: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState(['sectionsBySlug']),

    content() {
      return this.section.content ? this.section.content.rendered : LOADING_TEXT
    },

    section() {
      return this.sectionsBySlug[this.sectionSlug] || {}
    },

    title() {
      return this.section.title ? this.section.title.rendered : LOADING_TEXT
    },

    sectionStale() {
      return Date.now() - this.section.__readAt > 60 * 1000 * 10
    },

    shouldGetSection() {
      return !this.sectionsBySlug[this.sectionSlug] || this.sectionStale
    },

    embedded() {
      return this.section._embedded || {}
    },

    featuredMedia() {
      return this.embedded['wp:featuredmedia'] || {}
    },

    featuredImage() {
      return this.featuredMedia['0'] || {}
    },

    featuredImageUrl() {
      return this.featuredImage.source_url
    },

    featuredImageMediaDetails() {
      return this.featuredImage.media_details || {}
    },

    featuredImageSizes() {
      return this.featuredImageMediaDetails.sizes || {}
    },

    featuredImageVersions() {
      return Object.keys(this.featuredImageSizes).map((key) => {
        const size = this.featuredImageSizes[key]

        return {
          width: size.width,
          url: size.source_url,
        }
      })
    },
  },

  methods: {
    ...mapActions(['readSection']),

    getData() {
      const slug = this.sectionSlug
      this.loading = true
      return this.readSection({slug}).then(() => {
        this.loading = false
      })
    },

    updateData() {
      if (this.shouldGetSection) {
        this.loading = true

        this.getData().then(() => {
          this.loading = false
        })
      }
    },
  },

  serverPrefetch() {
    return this.getData()
  },

  mounted() {
    this.updateData()
  },

  watch: {
    $route() {
      this.updateData()
    },
  },
}
</script>

<style lang="sass" scoped>
.section-data-wrapper
  &.is-loading
    height: 200px
    display: flex
    justify-content: center
    align-items: center
</style>
