<template>
  <div class="page-timetable">
    <Banner :image-versions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <SectionDataWrapper sectionSlug="classes-preview">
      <template v-slot:default="slotProps">
        <Section class="is-odd">
          <h2 v-html="slotProps.title"></h2>

          <div v-html="slotProps.content"></div>
          <p>
            See our
            <RouterLink :to="{name: 'classes'}">classes</RouterLink> information
            for more details.
          </p>

          <ClassDataWrapper />
        </Section>
      </template>
    </SectionDataWrapper>

    <LocationSection contactLink className="is-even" />

    <GallerySection className="is-odd" />
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import ClassDataWrapper from '@/components/ClassDataWrapper'
import Section from '@/components/Section'
import SectionDataWrapper from '@/components/SectionDataWrapper'
import GallerySection from '@/components/GallerySection'
import LocationSection from '@/components/LocationSection'

export default {
  name: 'Timetable',

  mixins: [page],

  data() {
    return {
      pageSlug: 'timetable',
    }
  },

  components: {
    Banner,
    ClassDataWrapper,
    GallerySection,
    LocationSection,
    Section,
    SectionDataWrapper,
  },
}
</script>

<style lang="sass" scoped>
.page-timetable
</style>
