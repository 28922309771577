import { render, staticRenderFns } from "./Testimonial.vue?vue&type=template&id=cf8caa32&"
import script from "./Testimonial.vue?vue&type=script&lang=js&"
export * from "./Testimonial.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Testimonial.vue?vue&type=style&index=0&id=cf8caa32&prod&lang=sass&scpoed=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "7e9a3224"
  
)

export default component.exports