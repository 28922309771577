<template>
  <SectionDataWrapper class="gallery-section" sectionSlug="gallery">
    <template v-slot:default="slotProps">
      <Section :class="className">
        <h2 v-html="slotProps.title"></h2>

        <GalleryDataWrapper />

        <div v-html="slotProps.content"></div>
      </Section>
    </template>
  </SectionDataWrapper>
</template>

<script>
import GalleryDataWrapper from '@/components/GalleryDataWrapper'
import Section from '@/components/Section'
import SectionDataWrapper from '@/components/SectionDataWrapper'

export default {
  name: 'GallerySection',

  props: {
    className: {
      type: String,
      required: true
    }
  },

  components: {
    GalleryDataWrapper,
    Section,
    SectionDataWrapper
  }
}
</script>

<style lang="sass" scoped>
.gallery-section
</style>
