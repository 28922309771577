import {request} from '@/lib/request'

function classesPath() {
  return `/classes?per_page=100`
}

export function readClasses() {
  return request('get', classesPath()).then(({data}) => {
    return data
  })
}
