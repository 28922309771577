<template>
  <div @click="goToMap" class="map">
    <LazyImg :url="src" alt="Koba jiujitsu location" />
  </div>
</template>

<script>
import LazyImg from '@/components/LazyImg'
export default {
  name: 'Map',

  components: {
    LazyImg
  },

  data() {
    return {
      location: '51.224586416998896, -0.3344801386279523',
      center: '51.224586416998896, -0.3344801386279523',
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      zoom: 17,
      size: '800x800',
      type: 'roadmap',
      scale: 2
    }
  },

  computed: {
    marker() {
      return `mid|red|${this.location}`
    },

    mapsUrl() {
      return 'https://www.google.com/maps/dir//26-28+Hampstead+Rd,+Dorking+RH4+3AF/@51.2244039,-0.3361909,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4875e706ee8730a1:0x2b7f927778e479ad!2m2!1d-0.3340022!2d51.2244039'
    },

    src() {
      // TODO: extract style
      return `
      https://maps.googleapis.com/maps/api/staticmap?key=${this.key}&center=${this.center}&zoom=${this.zoom}&format=jpg&maptype=${this.type}&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels%7Cvisibility:off&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text%7Cvisibility:off&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels%7Cvisibility:off&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&size=${this.size}&scale=${this.scale}&markers=${this.marker}`
    }
  },

  methods: {
    goToMap() {
      window.location.replace(this.mapsUrl)
    }
  }
}
</script>

<style lang="sass" scoped>
.map
  cursor: pointer

  img
    width: 100%

  ::v-deep .lazy-img
    text-align: center
</style>
