<template>
  <Section class="instructor">
    <h2 v-html="name" class="name"></h2>
    <h3 v-html="role" class="role"></h3>

    <div class="belt">
      <div class="belt-colour" :style="{background: beltColour}"></div>

      <div :style="{color: beltColour}">{{ beltDescription }}</div>
    </div>

    <div v-html="content"></div>

    <template v-slot:aside v-if="featuredImageUrl">
      <LazyImg :url="featuredImageUrl" :description="title" />
    </template>
  </Section>
</template>

<script>
import Section from '@/components/Section'
import LazyImg from '@/components/LazyImg'

import PostMixin from '@/mixins/wp_post'

import {decodeHtml} from '@/filters'

export default {
  name: 'Instructor',

  mixins: [PostMixin],

  props: {
    instructor: {
      type: Object,
      required: true
    }
  },

  components: {
    LazyImg,
    Section
  },

  computed: {
    post() {
      return this.instructor
    },

    name() {
      if (!this.post.title) {
        return 'Loading...'
      }

      return decodeHtml(this.post.title.rendered)
    },

    acf() {
      return this.post.acf
    },

    role() {
      return this.acf.role
    },

    beltColour() {
      return this.acf.belt
    },

    beltDescription() {
      return this.acf.belt_description
    },

    content() {
      if (!this.post.content) {
        return ''
      }
      return this.post.content.rendered
    }
  }
}
</script>

<style lang="sass" scoped>
.instructor
  .name
    margin-bottom: 10px
    font-size: 28px

  .role
    font-size: 16px
    margin-bottom: 4px

  .belt
    margin-bottom: 28px
    display: flex
    align-items: center
    font-weight: bold
    font-size: 12px

    .belt-colour
      width: 12px
      height: 12px
      margin-right: 10px
</style>
