<template>
  <SectionDataWrapper class="wrapped-section" :sectionSlug="slug">
    <template v-slot:default="slotProps">
      <Section :class="className">
        <h2 v-html="slotProps.title"></h2>

        <div>
          <div v-html="slotProps.content"></div>
          <slot></slot>
        </div>

        <template v-slot:aside v-if="slotProps.featuredImageVersions.length">
          <AspectRatioWrapper :ratio="[4, 6]">
            <ResponsiveImage
              :image-versions="slotProps.featuredImageVersions"
              :description="slotProps.title"
              :width="width"
            />
          </AspectRatioWrapper>
        </template>
      </Section>
    </template>
  </SectionDataWrapper>
</template>

<script>
import ResponsiveImage from '@/components/ResponsiveImage'
import Section from '@/components/Section'
import SectionDataWrapper from '@/components/SectionDataWrapper'

import sizable from '@/mixins/sizable'
import AspectRatioWrapper from './AspectRatioWrapper.vue'

export default {
  name: 'WrappedSection',

  mixins: [sizable],

  props: {
    slug: {
      type: String,
      required: true,
    },

    className: {
      type: String,
      required: true,
    },
  },

  components: {
    ResponsiveImage,
    Section,
    SectionDataWrapper,
    AspectRatioWrapper,
  },
}
</script>

<style lang="sass" scoped>
.wrapped-section
</style>
