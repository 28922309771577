<template>
  <div class="page-teens-classes">
    <Banner :image-versions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first teens BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <WrappedSection slug="teens-classes-intro" className="is-odd" />

    <Section class="is-even">
      <h2>Teens BJJ classes timetable</h2>

      <ClassDataWrapper :filter="['Teens']" />
    </Section>

    <LocationSection className="is-odd" />
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import ClassDataWrapper from '@/components/ClassDataWrapper'
import Section from '@/components/Section'
import WrappedSection from '@/components/WrappedSection'
import LocationSection from '@/components/LocationSection'

export default {
  name: 'TeensClasses',

  mixins: [page],

  data() {
    return {
      pageSlug: 'teens-classes',
    }
  },

  components: {
    Banner,
    ClassDataWrapper,
    Section,
    WrappedSection,
    LocationSection,
  },
}
</script>

<style lang="sass" scoped>
.page-teens-classes
</style>
