<template>
  <div class="gallery-data-wrapper">
    <Gallery :items="galleryItems" />
  </div>
</template>

<script>
import Gallery from '@/components/Gallery'

import {mapState, mapActions} from 'vuex'

import data_wrapper from '@/mixins/data_wrapper'

export default {
  name: 'GalleryDataWrapper',

  mixins: [data_wrapper],

  components: {
    Gallery
  },

  computed: {
    ...mapState(['galleryItems', 'galleryItemsReadAt']),

    shouldReadGalleryImages() {
      return this.resourceStale(this.galleryItemsReadAt)
    }
  },

  methods: {
    ...mapActions(['readGalleryItems'])
  },

  serverPrefetch() {
    return this.readGalleryItems()
  },

  mounted() {
    if (this.shouldReadGalleryImages) {
      this.readGalleryItems()
    }
  }
}
</script>

<style lang="sass" scoped>
.gallery-data-wrapper
</style>
