<template>
  <p class="address">
    <Sprite v-if="!noIcon" name="location" class="address-icon" />
    <span class="p-name">Koba BJJ</span>

    <span class="p-adr h-adr">
      <span class="">dojo</span>
      <span class="p-street-address">26-28 Hampstead Road</span>
      <span class="p-locality">Dorking</span>
      <span class="p-region">Surrey</span>
      <span class="p-postal-code">RH4 3AF</span>
      <span class="p-country-name">UK</span>
    </span>

    <a class="maps" :href="mapsUrl" target="_blank">View on map</a>
  </p>
</template>

<script>
import Sprite from '@/components/Sprite'

export default {
  name: 'Address',

  props: {
    'no-icon': {
      type: Boolean,
      default: false
    }
  },

  components: {
    Sprite
  },

  data() {
    return {
      mapsUrl:
        'https://www.google.com/maps/dir//26-28+Hampstead+Rd,+Dorking+RH4+3AF/@51.2244039,-0.3361909,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4875e706ee8730a1:0x2b7f927778e479ad!2m2!1d-0.3340022!2d51.2244039'
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.address
  span
    display: block

  .address-icon
    width: 22px
    height: 22px
    fill: $color-light

  .maps
    font-size: 85%
</style>
