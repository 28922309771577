import { render, staticRenderFns } from "./FAQ.vue?vue&type=template&id=74c54dd7&scoped=true&"
import script from "./FAQ.vue?vue&type=script&lang=js&"
export * from "./FAQ.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./FAQ.vue?vue&type=style&index=0&id=74c54dd7&prod&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "74c54dd7",
  "521ae6a7"
  
)

export default component.exports