<script>
import QuoteIcon from '@/components/icons/QuoteIcon'

export default {
  name: 'Testimonial',

  props: {
    testimonial: Object,
  },

  data() {
    return {}
  },

  components: {
    QuoteIcon,
  },
}
</script>

<template>
  <div class="testimonial">
    <blockquote>
      <QuoteIcon class="icon open" />

      <span v-html="testimonial.content"></span>

      <footer>
        - <cite class="author">{{ testimonial.author }}</cite>
      </footer>
    </blockquote>
  </div>
</template>

<style lang="sass" scpoed>
@import '~@/stylesheets/base/_variables'
@import '~@/stylesheets/helpers/_mixins'

.testimonial
  display: flex
  align-self: center
  justify-content: center

  blockquote
    font-family: $font-testimonials
    font-size: clamp(20px, 2vw, 30px)

    letter-spacing: 0px
    line-height: 1.25

    .icon
      width: clamp(30px, 3vw, 45px)
      height: clamp(30px, 3vw, 45px)
      fill: #000000
      margin-right: $pad * 0.5

      &.open
        transform: rotate(180deg)

    footer
      margin-top: clamp($pad, 2vw, $pad*2)

      .author
</style>
