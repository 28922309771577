<template>
  <div class="page-article">
    <Banner :image-versions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <Container>
      <h1 v-html="articleTitle"></h1>

      <div v-html="this.content"></div>

      <div class="more">
        <RouterLink :to="{name: 'blog'}">More posts</RouterLink>
      </div>
    </Container>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import Container from '@/components/Container'

import page from '@/mixins/page'

import {decodeHtml} from '@/filters'

import {mapState, mapActions} from 'vuex'

export default {
  name: 'Article',

  mixins: [page],

  props: {
    year: {
      required: true,
    },

    month: {
      required: true,
    },

    day: {
      required: true,
    },

    slug: {
      required: true,
    },
  },

  components: {
    Banner,
    Container,
  },

  computed: {
    ...mapState(['postsBySlug']),

    shouldGetArticle() {
      return !this.postsBySlug[this.slug]
    },

    article() {
      return this.postsBySlug[this.slug] || {}
    },

    articleTitle() {
      if (!this.article.title) {
        return 'Loading...'
      }

      return this.article.title.rendered
    },

    title() {
      if (!this.article.title) {
        return 'Loading...'
      }

      return `${decodeHtml(this.article.title.rendered)} | Koba BJJ`
    },

    content() {
      if (!this.article.content) {
        return ''
      }
      return this.article.content.rendered
    },

    embedded() {
      return this.article._embedded || {}
    },

    featuredMedia() {
      return this.embedded['wp:featuredmedia'] || {}
    },

    featuredImage() {
      return this.featuredMedia['0'] || {}
    },

    featuredImageUrl() {
      return this.featuredImage.source_url || null
    },
  },

  methods: {
    ...mapActions(['readPost']),

    getData() {
      const year = this.year
      const month = this.month
      const day = this.day
      const slug = this.slug

      return this.readPost({year, month, day, slug})
    },

    updateData() {
      if (this.shouldGetArticle) {
        this.getData()
      }
    },
  },

  serverPrefetch() {
    return this.getData()
  },

  mounted() {
    this.updateData()
  },

  watch: {
    $route() {
      this.updateData()
    },
  },
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.page-article

  .more
    a
      color: $color-highlight
</style>
