import {request} from '@/lib/request'

function galleryPath() {
  return '/images?_embed=true'
}

export function getGallery() {
  return request('get', galleryPath()).then(({data}) => {
    return data
  })
}
