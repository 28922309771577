<template>
  <PostDataWrapper :count="1">
    <template v-slot:default="slotProps">
      <LatestPost
        v-if="slotProps.lastPost.id"
        :post="slotProps.lastPost"
        :class="className"
      />
    </template>
  </PostDataWrapper>
</template>

<script>
import PostDataWrapper from '@/components/Blog/PostDataWrapper'
import LatestPost from './LatestPost'

export default {
  name: 'LatestPosts',

  props: {
    className: {
      type: String,
      required: true
    }
  },

  components: {
    PostDataWrapper,
    LatestPost
  }
}
</script>

<style lang="sass" scoped>
@import '~@/stylesheets/base/variables'
@import '~@/stylesheets/helpers/mixins'

.latest-posts
  .post-list
    @include list
</style>
