<template>
  <div
    id="app"
    class="app"
    :class="{'is-navigating': navigating, 'is-scrolling': scrolling}"
  >
    <Header
      @toggleNav="onToggleNav"
      :navigating="navigating"
      :loading="loading"
    />

    <main>
      <Transition name="fade" mode="out-in">
        <RouterView />
      </Transition>
    </main>

    <Footer />

    <ClientOnly>
      <VueWhatsappWidget
        v-if="showWhatsApp"
        class="whatsapp-chat"
        phoneNumber="+44 7706 697339"
        company-name="Koba BJJ"
        :company-logo="require('./assets/img/logo.png')"
      />
    </ClientOnly>
  </div>
</template>

<script>
import throttle from 'lodash.throttle'

import ClientOnly from 'vue-client-only'

import Footer from '@/components/Footer'
import Header from '@/components/Header'

import {mapGetters} from 'vuex'

const NAVIGATING_CLASS = 'is-navigating'

export default {
  name: 'App',

  // Vue meta defaults
  metaInfo: {
    title: 'Koba BJJ',

    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Koba BJJ Brazilian Jiu-jitsu classes in Dorking, Surrey',
      },

      {vmid: 'og:title', property: 'og:title', content: 'Koba BJJ'},
      {vmid: 'og:type', property: 'og:type', content: 'article'},
      {vmid: 'og:url', property: 'og:url', content: 'https://kobabjj.com'},
      {
        vmid: 'og:image',
        property: 'og:image',
        content: 'https://koba-bjj.com/koba_bjj.png',
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: 'Koba BJJ Brazilian Jiu-jitsu classes in Dorking, Surrey',
      },
      {
        vmid: 'og:site_name',
        property: 'og:site_name',
        content: 'Koba BJJ',
      },
    ],
  },

  components: {
    ClientOnly,
    Footer,
    Header,
    VueWhatsappWidget: () => import('vue-whatsapp-widget'),
  },

  data() {
    return {
      navigating: false,
      scrollListener: null,
      scrolling: false,
    }
  },

  computed: {
    ...mapGetters(['loading']),

    showWhatsApp() {
      return typeof document !== 'undefined'
    },
  },

  methods: {
    onToggleNav() {
      this.navigating = !this.navigating
    },

    checkScroll() {
      this.scrolling = !!window.scrollY
    },
  },

  watch: {
    navigating() {
      if (this.navigating) {
        document.body.classList.add(NAVIGATING_CLASS)
      } else {
        document.body.classList.remove(NAVIGATING_CLASS)
      }
    },
  },

  mounted() {
    // Keep track of weather user has scrolled down or not
    this.scrollListener = window.addEventListener(
      'scroll',
      throttle(() => {
        this.checkScroll()
      }),
      200,
      {trailing: true}
    )

    this.checkScroll()
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrollListener)
  },
}
</script>

<style lang="sass">
@import "./stylesheets/base/variables"
@import "./stylesheets/base/typography"
@import "./stylesheets/helpers/mixins"
@import "./stylesheets/helpers/transitions"

*, *:after
  box-sizing: border-box

button
  cursor: pointer

body
  margin: 0
  padding: 0
  background: $color-light
  overflow-x: hidden

  h1, h2, h3, h4, h5
    text-align: center

    @include media($media-gt-big)
      text-align: left

  &.with-overlay
    overflow-y: hidden

  &.is-navigating
    overflow-y: hidden

    @include media($media-gt-big)
      overflow-y: auto

  .app
    background: $color-light
    width: 100%
    min-height: 100vh
    display: flex
    flex-direction: column

    main
      @include media($media-gt-big)
        padding-top: 50px

  .btn
    display: inline-block
    padding: 2px 7px 4px
    background: $color-light
    color: $color-dark
    border: 0
    border-radius: 3px
    margin-top: 12px

    &:hover
      text-decoration: none

.whatsapp-chat
  .whatsapp-chat-header
    p
      color: #ffffff

  .close-chat
    text-decoration: none
</style>
