import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'

import Home from '@/views/Home'
import Contact from '@/views/Contact'
import About from '@/views/About'
import Privacy from '@/views/Privacy'
import FAQ from '@/views/FAQ'
import Blog from '@/views/Blog'
import Article from '@/views/Blog/Article'

import Classes from '@/views/Classes'
import KidsClasses from '@/views/Classes/KidsClasses'
import AdultsClasses from '@/views/Classes/AdultsClasses'
import TeensClasses from '@/views/Classes/TeensClasses'

import FundamentalsClasses from '@/views/Classes/FundamentalsClasses'

import Timetable from '@/views/Timetable'
import Membership from '@/views/Membership'
import Instructors from '@/views/About/Instructors'

Vue.use(Router)
Vue.use(VueMeta)

export function createRouter() {
  return new Router({
    mode: 'history',

    linkExactActiveClass: 'is-active',

    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      }

      if (to.hash) {
        return {
          selector: to.hash,
        }
      }

      return {x: 0, y: 0}
    },

    base: process.env.BASE_URL,

    routes: [
      {
        path: '/',
        name: 'home',
        component: Home,
      },

      {
        path: '/timetable',
        name: 'timetable',
        component: Timetable,
      },

      {
        path: '/membership',
        name: 'membership',
        component: Membership,
      },

      {
        path: '/about',
        name: 'about',
        component: About,
      },

      {
        path: '/about/instructors',
        name: 'about.instructors',
        component: Instructors,
      },

      {
        path: '/classes',
        name: 'classes',
        component: Classes,
      },

      {
        path: '/classes/adults',
        name: 'classes.adults',
        component: AdultsClasses,
      },

      {
        path: '/classes/kids',
        name: 'classes.kids',
        component: KidsClasses,
      },

      {
        path: '/classes/teens',
        name: 'classes.teens',
        component: TeensClasses,
      },

      {
        path: '/classes/fundamentals',
        name: 'classes.fundamentals',
        component: FundamentalsClasses,
      },

      {
        path: '/contact',
        name: 'contact',
        component: Contact,
      },

      {
        path: '/faq',
        name: 'faq',
        component: FAQ,
      },

      {
        path: '/privacy',
        name: 'privacy',
        component: Privacy,
      },

      {
        path: '/blog',
        name: 'blog',
        component: Blog,
      },

      {
        path: '/blog/:year/:month/:day/:slug',
        name: 'article',
        props: true,
        component: Article,
      },
    ],
  })
}
