<template>
  <div class="page-faq">
    <Banner :image-versions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <Container>
      <div class="content" v-html="content"></div>
    </Container>

    <GallerySection className="is-even" />
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import Container from '@/components/Container'
import GallerySection from '@/components/GallerySection'

export default {
  name: 'FAQ',

  mixins: [page],

  components: {
    Banner,
    Container,
    GallerySection,
  },

  data() {
    return {
      pageSlug: 'faq',
    }
  },
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"

.page-faq
  .content
    ::v-deep
      h2
        margin-bottom: 0
        padding: $pad 0 $pad / 2
</style>
