import {request} from '@/lib/request'

function postsPath() {
  return '/posts'
}

function postPath(slug) {
  return `${postsPath()}?slug=${slug}&_embed=true`
}

export function readPosts() {
  return request('get', `${postsPath()}?_embed=true`).then(({data}) => {
    return data
  })
}

export function readPost(slug) {
  return request('get', postPath(slug)).then(({data}) => {
    return data[0]
  })
}
