import {request} from '@/lib/request'

function sectionPath(slug) {
  return `/sections/?slug=${slug}&_embed`
}

export function readSection(slug) {
  return request('get', sectionPath(slug)).then(({data}) => {
    return data[0]
  })
}
